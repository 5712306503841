import { DeviceBaseFragment } from '@designage/gql';
import moment from 'moment';

const DEVICE_STATUS_ONLINE = 'Online';
const DEVICE_STATUS_CTRLOFFLINE = 'Not installed';
const DEVICE_STATUS_PANELOFF = 'Panel off';
const DEVICE_STATUS_OFFLINE = 'Offline';
const DEVICE_STATUS_INFO = 'Info';
const DEVICE_STATUS_WARNING = 'Warning';

export interface IDeviceStatusSummary {
  Online: number;
  CtrlOffline: number;
  PanelOff: number;
  Offline: number;
  Info: number;
  Warning: number;
  DevicesCount: number;
}
export enum DeviceStatusCode {
  online = 1, // green
  ctrloffline = 2, // another green
  paneloff = 3, // dark green
  offline = 4, // red
  info = 5, // blue
  warning = 6, // yellow
}

export enum DeviceStatusColor {
  online = '#63C330',
  ctrloffline = '#F0A6B2',
  paneloff = '#17360E',
  offline = '#f1556c',
  info = '#5367D0',
  warning = '#F4C462',
}

export class DeviceStatusInfo {
  static Online = new DeviceStatusInfo(
    DeviceStatusCode.online,
    DeviceStatusColor.online
  );
  static CtrlOffline = new DeviceStatusInfo(
    DeviceStatusCode.ctrloffline,
    DeviceStatusColor.ctrloffline
  );
  static PanelOff = new DeviceStatusInfo(
    DeviceStatusCode.paneloff,
    DeviceStatusColor.paneloff
  );
  static Offline = new DeviceStatusInfo(
    DeviceStatusCode.offline,
    DeviceStatusColor.offline
  );
  static Info = new DeviceStatusInfo(
    DeviceStatusCode.info,
    DeviceStatusColor.info
  );
  static Warning = new DeviceStatusInfo(
    DeviceStatusCode.warning,
    DeviceStatusColor.warning
  );

  static get DefaultStatus() {
    return this.CtrlOffline;
  }

  static StatusColors: string[] = [
    DeviceStatusColor.online,
    DeviceStatusColor.ctrloffline,
    DeviceStatusColor.paneloff,
    DeviceStatusColor.offline,
    DeviceStatusColor.info,
    DeviceStatusColor.warning,
  ];

  static StatusLabels: string[] = [
    DEVICE_STATUS_ONLINE,
    DEVICE_STATUS_CTRLOFFLINE,
    DEVICE_STATUS_PANELOFF,
    DEVICE_STATUS_OFFLINE,
    DEVICE_STATUS_INFO,
    DEVICE_STATUS_WARNING,
  ];
  Status: DeviceStatusCode;
  Color: string;

  private constructor(status: DeviceStatusCode, color: DeviceStatusColor) {
    this.Status = status;
    this.Color = color;
  }

  get StatusLabel(): string {
    return DeviceStatusInfo.StatusLabels[(this.Status as number) - 1];
  }

  get MapClusterFilter(): unknown {
    return ['==', ['get', 'Status'], this.Status];
  }

  static get Statuses(): DeviceStatusInfo[] {
    return [
      DeviceStatusInfo.Online,
      DeviceStatusInfo.CtrlOffline,
      DeviceStatusInfo.PanelOff,
      DeviceStatusInfo.Offline,
      DeviceStatusInfo.Info,
      DeviceStatusInfo.Warning,
    ];
  }
}
