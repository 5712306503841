import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import moment, { Moment } from 'moment';

/**
 * returns a string 'HH:mm' | null
 * @param time
 * @returns 'HH:mm' | null
 */
export function getLocalizedTime(time: string | Moment) {
  if (typeof time === 'string') {
    const dateTime = getDateTime(time);
    return moment(dateTime).local().format('HH:mm');
  } else if (moment.isMoment(time)) {
    return moment(time).local().format('HH:mm');
  } else {
    return null;
  }
}

export function getDateTime(time: string, day?: number, _date?: Date) {
  const date = new Date(
    (_date ? _date : new Date()).toISOString().split('T')[0] + 'T' + time
  );

  if (day) {
    date.setDate(day);
  }

  return date;
}
/**
 * returns 00:00:00 string formatted time
 * @param date
 * @returns
 */
export function getISOTimeNoTz(time: string | Date | Moment) {
  if (typeof time === 'string') {
    const dateTime = getDateTime(time);
    return moment(dateTime).format('HH:mm:ss');
  } else if (moment.isMoment(time)) {
    return moment(time).format('HH:mm:ss');
  } else {
    return null;
  }
}

/**
 * return '00:00:00Z' ZULU formatted time, DANGEROUS
 * @param date
 * @returns
 */
export function getISOTime(date: string | Date | Moment) {
  const splitChar = 'T';
  let time = '00:00:00Z';
  let splitValues: string[] = [];

  if (date instanceof Date || moment.isMoment(date)) {
    splitValues = date.toISOString().split(splitChar);
  } else {
    splitValues = date.split(splitChar);
  }

  if (splitValues[1]) {
    time = splitValues[1];
  }

  return time;
}

export const timeout = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function timeStringToStruct(value: string): NgbTimeStruct {
  // HH:mm:ss
  const timeElements = value.split(':');
  return {
    hour: parseInt(timeElements[0], 10),
    minute: parseInt(timeElements[1], 10),
    second: timeElements.length > 2 ? parseInt(timeElements[2], 10) : 0,
  };
}
/** HH:mm or HH:mm:ss */
export function timeStructToString(
  timeStruct: NgbTimeStruct,
  showSecond: boolean
) {
  let timeString = `${String(timeStruct.hour).padStart(2, '0')}:${String(
    timeStruct.minute
  ).padStart(2, '0')}`;
  if (showSecond) {
    timeString += `:${String(timeStruct.second).padStart(2, '0')}`;
  }

  return timeString;
}

/** this function converts time format from HH:MM (HM) to HH:MM:SS (HMS) */
export const formatTimeHMToHMS = (timeHMformatted: string) => {
  const HH = timeHMformatted.split(':')[0];
  const MM = timeHMformatted.split(':')[1];
  // the back end needs the time format to be in 24hrs HH:MM:SS
  // since the SS (seconds) is not specified it should be 00
  return `${HH}:${MM}:00`;
};

/** Return HH:mm:ss  from date with time zone offset */
export function getDeviceTime(time: string, timeZoneOffset: string) {
  return moment(time).utcOffset(timeZoneOffset).format('HH:mm:ss');
}
